<template>

  <div>

    <div class="generatePristopnaIzjavaOuterContainer">

      <div id="my-node3" class="" v-if="volunteersData">

        <div class="generatePristopnaIzjava">

          <div id="my-node6">

            <div class="generatePristopnaIzjavaContainer">
              <img :src="require('@/assets/images/content/business_letters/pristopna_izjava_Triglav.png')" class="bgImg">
              <div class="name"><u>{{ volunteersData.first_name + ' ' + volunteersData.last_name }},</u></div>
              <div class="birthday"><u>{{ moment(volunteersData.birthday).format('DD. MM. YYYY') }},</u></div>
              <div class="address"><u>{{volunteersData.addresses.quick_search}}.</u></div>
              <div class="unit" style="width: 250px;"><u>{{volunteersData.units.name}} ({{volunteersData.units.coveredMunicipalities}})</u></div>
              <div class="unit2"> {{volunteersData.units.locationForContracts}}, _______________</div>
              <div class="signature">___________________________</div>

            </div>
          </div>
        </div>



      </div>

    </div>

  </div>

</template>

<script>
import Vue from "vue";

export default {
  name: 'PristopnaIzjavaZaZavarovanje',

  components: {
  },
  data() {
    return {
      volunteersData: null
    }
  },

  computed: {

  },

  mounted() {
    const _this = this;

    _this.$vs.loading();

    console.log(Vue.prototype.$http.defaults.headers);
    console.log("this.$route.query", this.$route.query);

    if (_this.$route.params.volunteerId) {
      if (this.$route.query.jwt) {
        Vue.prototype.$http.interceptors.request.use(
          config => {
            const token = this.$route.query.jwt;
            if (token) {
              if (config.url.includes('https://api.sopotniki.org/')) {
                config.headers.common["Authorization"] = 'Bearer ' + token;
              } else {
                config.headers.common["Authorization"] = token;
              }
            }
            return config;
          },
          error => {
            return Promise.reject(error);
          }
        );

      }


      _this.getVolunteerData();


    } else {
      _this.$vs.loading.close();
    }
  },


  methods: {
    async getVolunteerData() {
      const _this = this;
      _this.$vs.loading();

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'volunteers/' + _this.$route.params.volunteerId)
        .then((res) => {
          _this.volunteersData = res.data.data;
          _this.$vs.loading.close();
          console.log("volunteersData: ", res.data.data);
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },
    pad(num, size) {
      let s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },
  }
}
</script>

<style lang="scss">
html, body {
  width: 210mm;
  height: 297mm;
}
@page {
  size: A4;
  margin: 0;
  -webkit-print-color-adjust: exact !important;
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}

.generatePristopnaIzjavaOuterContainer {
  font-family: 'Open Sans' !important;
  color: black !important;

  .generatePristopnaIzjavaContainer {
    width: 210mm;
    height: 296mm;
    background-size: contain !important;
    -webkit-print-color-adjust: exact !important;
    padding: 18% 20% 15% 7% !important;
    display: block !important;

    overflow: hidden !important;

    font-size: 10px;
    text-align: justify;

    page-break-inside: avoid;
    position: relative;

    .bgImg {
      width: 210mm;
      height: 296mm;
      position: absolute;
      z-index: -1;
      left: 0px;
      top: 0px;
    }


    .name {
      text-transform: uppercase;
      font-size: 18px;
      position: absolute;
      left: 232px;
      top: 242px;
      font-weight: bold;
    }

    .birthday {
      text-transform: uppercase;
      font-size: 18px;
      position: absolute;
      left: 193px;
      top: 265px;
      font-weight: bold;
    }

    .address {
      text-transform: uppercase;
      font-size: 18px;
      position: absolute;
      left: 309px;
      top: 287px;
      max-width: 385px;
      font-weight: bold;
    }

    .unit {
      text-transform: uppercase;
      font-size: 18px;
      position: absolute;
      top: 357px;
      left: 500px;
      max-width: 385px;
      font-weight: bold;
    }

    .unit2 {
      position: absolute;
      top: 637px;
      left: 98px;
      font-size: 18px;
    }

    .signature {
      position: absolute;
      top: 637px;
      left: 503px;
      font-size: 18px;
    }

  }

}
</style>
